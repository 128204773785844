<template>
  <div id="resultat">
    <div
      class="row"
      style="margin-bottom: 20px;"
    >
      <div class="col-9 text-left">
        <div class="row">
          <div class="col-3">
            <img
              :src="logoClinique"
              style="width: 120px;"
            >
          </div>
          <div class="col-9 text-center">
            <h4 style="text-transform: uppercase; padding-top: 10px; font-size: 1.3rem; color: green;">
              {{ cliniqueName }}
            </h4>
            <small>KEGUE VON EPP KELEGOUGAN PRES ECHANGEUR</small><br>
            <small>RCCM: TG-LOM 2015 B 1735 - NIF: 1000582678 - 16BP375 - 90808147</small>
          </div>
        </div>
      </div>
        
      <div class="col-3 text-right">
        <img
          :src="logoCaseac"
          style="width: 120px;"
        >
      </div>
    </div>
    <div class="row">
      <div
        class="col-12"
        style="margin-top: 20px; margin-bottom: 20px; text-align: center;"
      >
        <slot name="title" />
      </div>
    </div>

    <div
      class="row animal"
      v-if="hasAnimal"
    >
      <div class="col-4">
        <div class="row">
          <div class="col-12">
            <label> Nom: </label>
            {{ animal.name }}
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label> Code ISO: </label>
            {{ animal.codeIso }}
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row">
          <div class="col-12">
            <label>Espèce:</label>
            {{ espece | libelle }}
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label>Race:</label>
            {{ race |libelle }}
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row">
          <div class="col-12">
            <label>Age: </label>
            {{ animal.dateNaissance | age }}
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label>Sexe: </label>
            {{ animal.sexe|sexe }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <slot name="content" />
      </div>
    </div>

    <div
      class="row"
      style="margin-top: 20px; margin-bottom: 100px;"
    >
      <div class="col-6">
        <label>Date:</label>
        {{ dateObject|systemDateTime }}
      </div>
      <div class="col-6">
        Signature
      </div>
    </div>
  </div>
</template>

<script>
const  logoCaseac = require('../../../../assets/img/logo256.png')
const logoClinique = require('../../../../assets/img/clinique_logo.png')
import { CLINIQUE_FICHE_NAME } from '../../../../constants/app';
import { mapGetters } from 'vuex';
import moment from 'moment'
export default {
    props: {
        animalUid: {type: String, required: true},
        date: {type: String, default: null}
    },
    data(){
        return {
          logoCaseac,
          logoClinique
        }
    },
    computed:{
        ...mapGetters({
            animaux: 'identification/animaux',
            especes: 'identification/especes',
            races: 'identification/races'
        }),
        animal(){
            let a = this.animaux.find(item => item.uid === this.animalUid)
            if(a) return a
            return null
        },
        hasAnimal(){
            return this.animal !== null && this.animal !== undefined
        },
        espece(){
            return this.especes.some(item => item.uid === this.animal.espece)? this.especes.find(item =>item.uid === this.animal.espece) : null
        },
        race(){
            return this.races.some(item => item.uid === this.animal.race)? this.races.find(item =>item.uid === this.animal.race) : null
        },
        cliniqueName(){
            return CLINIQUE_FICHE_NAME
        },
        dateObject(){
            return moment(this.date)
        }
    }
}
</script>

<style lang="scss" scoped>
.animal{
    padding: 20px;
    border: solid 1px rgba($color: #000000, $alpha: 0.15);
    margin: 10px 0px 20px 0px;
    label{
        width: 25%;
    }
}
</style>